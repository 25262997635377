import React from 'react';


export const loan = (value, percentage) => {
  return parseInt((parseFloat(value) * (parseFloat(percentage)/100)));
}

export const maxLoan = (amount, percentage, maxLoanAmount=40000000) => {
  return Math.min(parseInt((parseFloat(amount) * (percentage/100))), maxLoanAmount)
}

export const toMoney = (n, decimals, decimal_sep, thousands_sep) => {
  let c = isNaN(decimals) ? 2 : Math.abs(decimals); //if decimal is zero we must take it, it means user does not want to show any decimal
  let d = decimal_sep || '.'; //if no decimal separator is passed we use the dot as default decimal separator (we MUST use a decimal separator)

  /*
    according to [http://stackoverflow.com/questions/411352/how-best-to-determine-if-an-argument-is-not-sent-to-the-javascript-function]
    the fastest way to check for not defined parameter is to use typeof value === 'undefined'
    rather than doing value === undefined.
  */
  let t = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep; //if you don't want to use a thousands separator you can pass empty string as thousands_sep value

  let sign = (n < 0) ? '-' : '';

  //extracting the absolute value of the integer part of the number and converting to string
  let i = parseInt(n = Math.abs(n).toFixed(c), 10) + '';

  let j = ((j = i.length) > 3) ? j % 3 : 0;
  return sign + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : '');
}

export const formatISK = (d) => {
  return toMoney(d, 0, ',', '.');
}

export const formatPercentage = (d, precision = 2) => {
  return toMoney(d, precision, ',', '.');
}

export const buildParams = (terms, maxLoanPercentage, ) => {
    return {
      a: terms.loanAmount,
      m: terms.marketPrice,
      mi: terms.marketPriceIncrease,
      p1: terms.primaryLoanPercentage,
      p2: terms.maxLoanPercentage,
      t1: terms.primaryLoanType,
      t2: terms.secondaryLoanType,
      y1: terms.primaryLoanLength,
      y2: terms.secondaryLoanLength,
      i1: terms.primaryInterestRate,
      i2: terms.secondaryInterestRate,
      vt1: terms.primaryLoanIndexed,
      vt2: terms.secondaryLoanIndexed,
      f1: terms.primaryInflationForecast,
      f2: terms.secondaryInflationForecast,
      s1: terms.primaryPaymentFee,
      s2: terms.secondaryPaymentFee,
    }
}

export const buildMultiParams = (terms, loanOptions) => {
    let selectedIndexedLoan = loanOptions.primaryLoansIndex[terms.selectedIndexedLoanIndex]
    let selectedIndexedLoan2 = loanOptions.primaryLoansIndex[terms.selectedIndexedLoanIndex2]
    let selectedNonIndexedLoan = loanOptions.primaryLoansNoIndex[0]
    let comboLoans = [selectedIndexedLoan2, selectedNonIndexedLoan]
    let loans = loanOptions.primaryLoans.concat(comboLoans)
    let i = loans.map((x) => x.value);
    for (var x=0;x<i.length;x++) {
        if (terms.overrideInterests) {
            i[x] = terms.overrideInterestsValues
        }
    }
    return {
        a: `${terms.loanAmount},${terms.loanAmount},${terms.loanAmount},${Math.round(terms.loanAmount * terms.loanSplit/100)},${Math.round(terms.loanAmount * (1-terms.loanSplit/100))}`,
        f: terms.multiLoanInflationForecast === "custom" ? parseFloat(terms.customInflationForecast.replace(",", "."))/100 : terms.multiLoanInflationForecast,
        i: i.join(","),
        p: `${terms.primaryLoanPercentage},${terms.primaryLoanPercentage},${terms.primaryLoanPercentage},${terms.primaryLoanPercentage},${terms.primaryLoanPercentage}`,
        t: terms.multiLoanTypes.join(","),
        y: terms.multiLoanLength.join(","),
        vt: loans.map((x) => `${x.indexed}`).join(","),
        s: `${terms.primaryPaymentFee},${terms.primaryPaymentFee},${terms.primaryPaymentFee},${terms.primaryPaymentFee},${terms.primaryPaymentFee}`,
        vi: loans.map((x) => `${x.variable_interests}`).join(","),
        ft: 'regular,regular,regular,regular,discounted',
        m: terms.marketPrice,
        mi: terms.marketPriceIncrease,
    }
}

export const convertToHumanReadablePercentage = (value) => {
    return (""+value).replace(".", ",")
}

export const convertFromHumanReadablePercentage = (value) => {
    return parseFloat(value.replace(",","."))
}

export const getSelectedLoans = (props) => {

    let loanIndex = props.terms.selectedLoanIndex
    if (loanIndex < props.loanOptions.primaryLoansIndex.length) {
      loanIndex = props.terms.selectedIndexedLoanIndex
    }
    
    let loans = [props.multiLoan.loans[loanIndex]]

    if (loanIndex >= props.loanOptions.primaryLoans.length) {
      loans.push(props.multiLoan.loans[loanIndex + 1])
    }
    return loans;
}

export const renderYears = (min, max) => {
    var options = [];
    for(var i=min;i<=max;i++) {
        options.push(
        <option key={i} value={i}>{i} ár</option>
        );
    }
    return options;
}

export const renderInflationOptions = (loanOptions) => {
    var options = [];
    for(var i=0;i<loanOptions.inflationForecasts.length;i++) {
        options.push(
        <option key={i} value={loanOptions.inflationForecasts[i].value}>{loanOptions.inflationForecasts[i].label}</option>
        );
    }
    options.push(<option key={++i} value="custom">Eigin verðbólguspá</option>)
    return options;
}

export const renderInterestOptions = (loanOptions) => {
    var options = [];
    for(var i=0;i<loanOptions.length;i++) {
        options.push(
            <option key={i} value={i} >{loanOptions[i].label}</option>
            );
    }
    return options;
}

export const renderPaymentFees = (loanOptions) => {
    var options = [];
    if (loanOptions.paymentFees !== undefined){
        for(var i=0;i<loanOptions.paymentFees.length;i++) {
            options.push(
                <option key={i} value={loanOptions.paymentFees[i].value}>{loanOptions.paymentFees[i].value} kr. ({loanOptions.paymentFees[i].label})</option>
            );
        }
    }
    return options;
}


export const stopLabelPropagation= (e) => {
  e.stopPropagation();
}

export const LoanSplit = (props) => {
    let vt = Math.round(props.amount * props.split/100)
    let ovt = Math.round(props.amount * (1-props.split/100))
    return (
        <div className={`split row ${props.classNames}`}>
            <div className="col-6">{formatISK(vt)} VT</div>
            <div className="col-6">{formatISK(ovt)} ÓVT</div>
        </div>
    )
}


export const fractionalSkipRatio = (chart) => {
    var xScale = chart.scales['x-axis-0'];
    if (xScale.options.ticks.maxTicksLimit) {
        // store the original maxTicksLimit
        xScale.options.ticks._maxTicksLimit = xScale.options.ticks.maxTicksLimit;
        // let chart.js draw the first and last label
        xScale.options.ticks.maxTicksLimit = (xScale.ticks.length % xScale.options.ticks._maxTicksLimit === 0) ? 1 : 2;

        var originalXScaleDraw = xScale.draw
        xScale.draw = function () {
            //originalXScaleDraw.apply(this, arguments);

            var xScale = chart.scales['x-axis-0'];
            if (xScale.options.ticks.maxTicksLimit) {
                var helpers = Chart.helpers;

                var tickFontColor = helpers.getValueOrDefault(xScale.options.ticks.fontColor, Chart.defaults.global.defaultFontColor);
                var tickFontSize = helpers.getValueOrDefault(xScale.options.ticks.fontSize, Chart.defaults.global.defaultFontSize);
                var tickFontStyle = helpers.getValueOrDefault(xScale.options.ticks.fontStyle, Chart.defaults.global.defaultFontStyle);
                var tickFontFamily = helpers.getValueOrDefault(xScale.options.ticks.fontFamily, Chart.defaults.global.defaultFontFamily);
                var tickLabelFont = helpers.fontString(tickFontSize, tickFontStyle, tickFontFamily);
                var tl = xScale.options.gridLines.tickMarkLength;

                var isRotated = xScale.labelRotation !== 0;
                var yTickStart = xScale.top;
                var yTickEnd = xScale.top + tl;
                var chartArea = chart.chartArea;

                // use the saved ticks
                var maxTicks = xScale.options.ticks._maxTicksLimit - 1;
                var ticksPerVisibleTick = xScale.ticks.length / maxTicks;

                // chart.js uses an integral skipRatio - this causes all the fractional ticks to be accounted for between the last 2 labels
                // we use a fractional skipRatio
                var ticksCovered = 0;
                helpers.each(xScale.ticks.concat([xScale.ticks[xScale.ticks.length-1]]), function (label, index) {
                    if (index !== ticksCovered)
                        return;

                    ticksCovered += ticksPerVisibleTick;
                    // chart.js has already drawn these 2
                    // if (index === 0 || index === (xScale.ticks.length - 1))
                    //     return;

                    // copy of chart.js code
                    var xLineValue = this.getPixelForTick(index);
                    var xLabelValue = this.getPixelForTick(index, this.options.gridLines.offsetGridLines);

                    if (this.options.gridLines.display) {
                        this.ctx.lineWidth = this.options.gridLines.lineWidth;
                        this.ctx.strokeStyle = this.options.gridLines.color;

                        xLineValue += helpers.aliasPixel(this.ctx.lineWidth);

                        // Draw the label area
                        this.ctx.beginPath();

                        if (this.options.gridLines.drawTicks) {
                            this.ctx.moveTo(xLineValue, yTickStart);
                            this.ctx.lineTo(xLineValue, yTickEnd);
                        }

                        // Draw the chart area
                        if (this.options.gridLines.drawOnChartArea) {
                            this.ctx.moveTo(xLineValue, chartArea.top);
                            this.ctx.lineTo(xLineValue, chartArea.bottom);
                        }

                        // Need to stroke in the loop because we are potentially changing line widths & colours
                        this.ctx.stroke();
                    }

                    if (this.options.ticks.display) {
                        this.ctx.save();
                        this.ctx.translate(xLabelValue + this.options.ticks.labelOffset, (isRotated) ? this.top + 12 : this.options.position === "top" ? this.bottom - tl : this.top + tl);
                        this.ctx.rotate(helpers.toRadians(this.labelRotation) * -1);
                        this.ctx.font = tickLabelFont;
                        this.ctx.textAlign = (isRotated) ? "right" : "center";
                        this.ctx.textBaseline = (isRotated) ? "middle" : this.options.position === "top" ? "bottom" : "top";
                        this.ctx.fillText(label, 0, 0);
                        this.ctx.restore();
                    }
                }, xScale);
            }
        };
    }
}

export const findGetParameter = (parameterName) => {
    var result = null,
        tmp = [];
    var items = location.search.substr(1).split("&");
    for (var index = 0; index < items.length; index++) {
        tmp = items[index].split("=");
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    }
    return result;
}

export const getLoanTypeFromIndex = (idx) => {
    switch(idx) {
      case 0:
        return "indexed";
        break;
      case 1:
        return "mixed";
        break;
      case 2:
        return "nonindexed"
    }
  }

  export const getLoanTypeFromMultiloanIndex = (idx) => {
    switch(idx) {
      case 0:
        return "indexed";
        break;
      case 3:
        return "mixed";
        break;
      case 2:
        return "nonindexed"
    }
  }

  export const calculateSelectedPayments = (loans) => {
    var length = Math.max.apply(null, loans.map(loan => loan.payments.length))
    for (var j = 0; j < loans.length; j++) {
      // Fill rest of array with empty data
      if (loans[j].payments.length < length) {
        var arr = Array.from(Array(length - loans[j].payments.length), (d, x) => {
          return {
            index: loans[j].payments.length + 1 + x,
            balance: 0,
            interests: 0,
            payment: 0,
            slip: 0,
            principal_payment: 0,
            verdbaetur: 0,
            verdbaetur_v_vaxta: 0,
            market_price_adjusted: 0,
          }
        })
        loans[j].payments = loans[j].payments.concat(arr)
      }
    }

    var selectedPayments = [];
    for (var i = 0; i < length; i++) {
      var payment = {}
      Object.keys(loans[0].payments[0]).map((key) => {
        if (key !== 'market_price_adjusted') {
          payment[key] = loans.map(el => el.payments[i][key]).reduce((a, b) => a + b)
        } else {
          payment[key] = loans[0].payments[i][key]
        }
      })
      selectedPayments.push(payment)
    }
    return selectedPayments
  }
