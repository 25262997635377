'use strict';

import $ from 'jquery'
import moment from 'moment'

var MobileDetect = require('mobile-detect');
window.md = new MobileDetect(window.navigator.userAgent);

require('bootstrap-slider')
require('./components/loan_calculator')


import "../sass/loan_calculator.scss"

 