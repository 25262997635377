import React from 'react';
import ReactDOM from 'react-dom';
import { loan, maxLoan, buildParams, buildMultiParams, formatISK, formatPercentage, renderYears, renderInflationOptions, renderPaymentFees } from './utils';
import { setState, setMultiState, fetchLoans, fetchMultiLoans } from '../../redux/actions';
import RangeSlider from 'react-bootstrap-range-slider';
import Cleave from 'cleave.js/react';
import LoanDisclaimer from './loan-disclaimer';
import LoanSettings from './loan-settings';

window.ReactDOM = ReactDOM

export default class LoanHeader extends React.Component {

  constructor(props) {
    super(props);

    this.marketPriceChange = this.marketPriceChange.bind(this);
    this.loanAmountChange = this.loanAmountChange.bind(this);
    this.sliderChange = this.sliderChange.bind(this);
    this.refreshRangeHighlights = this.refreshRangeHighlights.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  calculateRangeHighlights() {
    return []
  }

  marketPriceChange(event) {
    var val = event.target.value.replace(/\./g, '');
    let newState = []

    if (parseInt(val) < this.props.terms.loanAmount) {
      var loanAmount = maxLoan(parseInt(val), this.props.terms.maxLoanPercentage * 100, this.props.terms.maxLoanAmount)
      if (loanAmount < 1000000) {
        loanAmount = 1000000;
      }
      newState.push({ key: 'loanAmount', value: loanAmount })
    }
    newState.push({ key: 'marketPrice', value: val })

    Promise.resolve(this.props.dispatch(setMultiState(newState)))
      .then(() => {
        if (this.props.terms.buttonClicked) {
          this.props.dispatch(fetchMultiLoans(buildMultiParams(this.props.terms, this.props.loanOptions)));
        }
      });
  }

  loanAmountChange(event) {
    var val = event.target.value.replace(/\./g, '');

    Promise.resolve(this.props.dispatch(setState({ key: 'loanAmount', value: val })))
      .then(() => {
        if (this.props.terms.buttonClicked && val >= 1000000) {
          this.props.dispatch(fetchMultiLoans(buildMultiParams(this.props.terms, this.props.loanOptions)));
        }
      });
  }

  sliderChange(event) {
    var val = event.target.value
    if (val < 1000000) {
      val = 1000000
    }
    Promise.resolve(this.props.dispatch(setState({ key: 'loanAmount', value: val })))
      .then(() => {
        if (this.props.terms.buttonClicked) {
          this.props.dispatch(fetchMultiLoans(buildMultiParams(this.props.terms, this.props.loanOptions)));
        }
      });
  }

  componentDidUpdate() {
    this.refreshRangeHighlights();
  }

  refreshRangeHighlights() {
    if (this.props.terms.loanAmount > loan(this.props.terms.marketPrice, this.props.terms.primaryLoanPercentage * 100)) {
      $('.slider-rangeHighlight').show();
    } else {
      $('.slider-rangeHighlight').hide();
    }
  }

  handleClick() {
    Promise.resolve(this.props.dispatch(setState({ key: 'buttonClicked', value: true })))
      .then(() => {
        this.props.dispatch(fetchMultiLoans(buildMultiParams(this.props.terms, this.props.loanOptions)));
      });
  }

  render() {
    var maxLoanAmount = maxLoan(parseInt(this.props.terms.marketPrice), this.props.terms.maxLoanPercentage * 100, this.props.terms.maxLoanAmount)
    var showResults = (this.props.terms.loanAmount <= maxLoanAmount)
    console.log("this.props.maxLoanPercentage", showResults)
    return (
      <div className="calculator row">
        <div className="col-lg-4 loan-calc-subhead">
          <label htmlFor="market-price">Fasteignamat</label>
          <Cleave placeholder="Fasteignamat"
            id="market-price"
            className="currency-input"
            options={{
              numeral: true,
              numeralDecimalMark: ',',
              delimiter: '.'
            }}
            value={this.props.terms.marketPrice}
            onChange={this.marketPriceChange}
          />
        </div>
        <div className="col-lg-4 loan-calc-subhead">
          <label htmlFor="loan-amount">Lánsfjárhæð</label>
          <Cleave placeholder="Lánsupphæð"
            id="loan-amount"
            className="currency-input"
            options={{
              numeral: true,
              numeralDecimalMark: ',',
              delimiter: '.'
            }}
            value={this.props.terms.loanAmount}
            onChange={this.loanAmountChange}
          />
          <RangeSlider
            id="loan-slider"
            className="slider slider-horizontal"
            value={this.props.terms.loanAmount}
            step={100000}
            onChange={this.sliderChange}
            max={maxLoanAmount}
            min={0}
            tooltipPlacement="bottom"
            tooltipLabel={() => `${formatPercentage(100.0 * this.props.terms.loanAmount / this.props.terms.marketPrice, 0)}% af fasteignamati`}
          />{this.props.sliderText !== "None" ? 
            <span>{this.props.sliderText}</span>
            :
            <></>
          }
        </div>
        <div className="calculate-btn">
          <button className="calculate" onClick={this.handleClick} disabled={this.props.terms.loanAmount < 1000000 || !showResults}>
            {this.props.multiLoan.isFetching ?
              <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
              :
              <span>Reikna</span>
            }
          </button>
          <LoanDisclaimer {...this.props} />
          {showResults && this.props.multiLoan.loanDataReceived && <LoanSettings {...this.props} />}
        </div>
      </div>
    );
  }
}
