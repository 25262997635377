import React from 'react';
import ReactDOM from 'react-dom';
import { loan, maxLoan, buildParams, buildMultiParams, formatISK, formatPercentage, renderInterestOptions, renderYears, getSelectedLoans, stopLabelPropagation, LoanSplit } from './utils';
import { setState, setMultiState, fetchLoans, fetchMultiLoans } from '../../redux/actions';
import RangeSlider from 'react-bootstrap-range-slider';
import Cleave from 'cleave.js/react';

import LoanPaymentSummary from './loan-payment-summary'
import LoanCostSummary from './loan-cost-summary';
import LoanPaymentsCharts from './loan-payments-charts';
import LoanPaymentsTable from './loan-payments-table';


window.ReactDOM = ReactDOM

export default class LoanOverview extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loanSplit: 50,
      activeLoanClass: 'loan-indexed'
    }
    this.onSelectPaymentType = this.onSelectPaymentType.bind(this);
    this.sliderChange = this.sliderChange.bind(this);
    this.sliderStop = this.sliderStop.bind(this);
    this.setActiveLoan = this.setActiveLoan.bind(this);
  }

  checkSliderValue(value) {
    if (this.props.terms.loanAmount >= 2000000) {
      let low = 100 * 1000000 / this.props.terms.loanAmount
      let high = 100 - low
      if (value < low) {
        value = low;
      }
      if (value > high) {
        value = high
      }
    } else {
      value = 50;
    }
    return value;
  }

  sliderStop(event) {
    var val = this.checkSliderValue(event.target.value)
    Promise.resolve(this.props.dispatch(setState({ key: 'loanSplit', value: val })))
      .then(() => {
        this.props.dispatch(fetchMultiLoans(buildMultiParams(this.props.terms, this.props.loanOptions)))
      });
  }

  sliderChange(event) {
    console.log("!slkdjfsldk")
    this.setState({ loanSplit: this.checkSliderValue(event.target.value) })
  }

  onSelectPaymentType(loanIndex, type) {
    console.log(loanIndex)
    console.log(type)
    let types = this.props.terms.multiLoanTypes
    let loan = this.props.loanOptions.primaryLoans[loanIndex]
    types[loanIndex] = type
    Promise.resolve(this.props.dispatch(setMultiState([
      { key: 'multiLoanTypes', value: types },
      { key: 'primaryInterestRate', value: loan.value },
      { key: 'primaryLoanIndexed', value: loan.indexed },
      //{key: 'selectedLoanIndex', value: loanIndex},
      { key: 'primaryLoanType', value: types[loanIndex] },
    ])))
      .then(() => {
        this.props.dispatch(fetchMultiLoans(buildMultiParams(this.props.terms, this.props.loanOptions)))
      });
  }

  onSelectChange(key, loanIndex, event) {
    if (key === 'selectedIndexedLoanIndex' || key === 'selectedIndexedLoanIndex2') {
      let _newState = [
        { key: key, value: event.target.selectedIndex },
      ]
      _newState.push()
      Promise.resolve(this.props.dispatch(setMultiState(_newState)))
        .then(() => {
          this.props.dispatch(fetchMultiLoans(buildMultiParams(this.props.terms, this.props.loanOptions)));
        });
    }

    if (key === 'multiLoanLength') {
      let val = event.target.value;
      let multiLoanLength = this.props.terms.multiLoanLength
      if (loanIndex < this.props.loanOptions.primaryLoansIndex.length) {
        //multiLoanLength[this.props.loanOptions.primaryLoansIndex.length+this.props.loanOptions.primaryLoansNoIndex.length] = val;
        multiLoanLength[loanIndex] = val;
        multiLoanLength[loanIndex + 1] = val;
      } else {
        multiLoanLength[loanIndex] = val;
        //multiLoanLength[loanIndex+2] = val;
      }


      Promise.resolve(this.props.dispatch(setMultiState([
        { key: 'multiLoanLength', value: multiLoanLength },
      ])))
        .then(() => {
          this.props.dispatch(fetchMultiLoans(buildMultiParams(this.props.terms, this.props.loanOptions)));
        });
    }

    if (key === 'multiLoanTypes') {
      let val = event.target.value;
      let multiLoanTypes = this.props.terms.multiLoanTypes
      if (loanIndex < this.props.loanOptions.primaryLoansIndex.length) {
        multiLoanTypes[loanIndex] = val;
      } else {
        multiLoanTypes[loanIndex] = val;
      }


      Promise.resolve(this.props.dispatch(setMultiState([
        { key: 'multiLoanTypes', value: multiLoanTypes },
      ])))
        .then(() => {
          this.props.dispatch(fetchMultiLoans(buildMultiParams(this.props.terms, this.props.loanOptions)));
        });
    }

  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.terms.loanSplit !== this.state.loanSplit) {
      this.setState({ loanSplit: nextProps.terms.loanSplit })
    }
  }


  getLoansToShow(props) {
    let loans = []
    let loanIndicesToShow = [0, 3, 2]
    loanIndicesToShow.map(i => {
      loans.push(props.multiLoan.loans[i])
    })
    return loans
  }

  setActiveLoan(className) {
    if ((window.md.tablet() || window.md.phone())) {
      this.setState({ activeLoanClass: className })
    }
  }


  render() {
    let i = 0


    let loanIndexed = this.props.multiLoan.loans[this.props.terms.selectedIndexedLoanIndex];
    let index = this.props.loanOptions.primaryLoansIndex ? this.props.loanOptions.primaryLoansIndex.length : 2;
    let loanNonIndexed = this.props.multiLoan.loans[index]
    let loansMixed = [this.props.multiLoan.loans[this.props.loanOptions.primaryLoans.length], this.props.multiLoan.loans[this.props.loanOptions.primaryLoans.length + 1]]

    let loans = this.props.multiLoan.loanDataReceived ? [loanIndexed, loansMixed, loanNonIndexed] : []


    return (

      <div className={`preview ${this.props.multiLoan.isFetching ? 'fetching' : ''}`} id="accordion">
        {this.props.multiLoan.loanDataReceived &&
          <>
            <div className={`loan-table open-${this.state.activeLoanClass}`}>
              <div className="loan-row total-payment">
                <div className="loan-cell loan-label">Meðalafborgun fyrsta árs</div>
                <div className="loan-cell loan-indexed" onClick={this.setActiveLoan.bind(this, 'loan-indexed')}>
                  <h3>Verðtryggt lán</h3>
                  {this.props.multiLoan.loanDataReceived && this.props.terms.loanAmount >= 1000000 ?
                    <div className="payment">
                      <div className="payment-wrapper">
                        {formatISK(loanIndexed.average_first_year_payment)}<span className="currency">kr.</span>
                      </div>
                    </div>
                    :
                    <div>Lágmarksupphæð láns er {formatISK(1000000)} kr.</div>
                  }
                </div>
                <div className="loan-cell loan-mixed" onClick={this.setActiveLoan.bind(this, 'loan-mixed')}>
                  <h3>Blandað lán</h3>
                  {this.props.multiLoan.loanDataReceived && this.props.terms.loanAmount >= 2000000 ?
                    <div>
                      <div className="payment">
                        <div className="payment-wrapper">
                          {formatISK(this.props.multiLoan.loans[this.props.loanOptions.primaryLoans.length].average_first_year_payment + this.props.multiLoan.loans[this.props.loanOptions.primaryLoans.length + 1].average_first_year_payment)}<span className="currency">kr.</span>
                        </div>
                      </div>
                    </div>
                    :
                    <div>Lágmarksupphæð blandaðs láns er {formatISK(2000000)} kr.</div>
                  }
                </div>
                <div className="loan-cell loan-nonindexed" onClick={this.setActiveLoan.bind(this, 'loan-nonindexed')}>
                  <h3>Óverðtryggt lán</h3>
                  {this.props.multiLoan.loanDataReceived && this.props.terms.loanAmount >= 1000000 ?
                    <div className="payment">
                      <div className="payment-wrapper">
                        {formatISK(loanNonIndexed.average_first_year_payment)}<span className="currency">kr.</span>
                      </div>
                    </div>
                    :
                    <div>Lágmarksupphæð láns er {formatISK(1000000)} kr.</div>
                  }
                </div>
              </div><div className="loan-row">
                <div className="loan-cell loan-label"></div>
                <div className="loan-cell loan-indexed"></div>
                <div className="loan-cell loan-mixed">
                  {this.props.multiLoan.loanDataReceived && this.props.terms.loanAmount >= 2000000 ?
                    <div>
                      <div className="payment">
                        <RangeSlider
                          id="loan-slider"
                          className="hidden-xs"
                          value={this.state.loanSplit}
                          step={1}
                          onChange={this.sliderChange}
                          onAfterChange={this.sliderStop}
                          min={0}
                          max={100}
                          tooltipLabel={() => `${formatPercentage(this.state.loanSplit, 0)}% verðtryggt, ${formatPercentage(100 - this.state.loanSplit, 0)}% óverðtryggt`}
                        />
                        <LoanSplit classNames="hidden-xs" split={this.state.loanSplit} amount={this.props.terms.loanAmount} />
                      </div>
                    </div>
                    :
                    <></>
                  }
                </div>
                <div className="loan-cell loan-nonindexed"></div>
              </div>
              <div className="loan-row">
                <div className="loan-cell loan-label">Lánstími</div>
                <div className="loan-cell loan-indexed">
                  <div className="select-wrapper">
                    <select onChange={this.onSelectChange.bind(this, 'multiLoanLength', 0)} value={this.props.terms.multiLoanLength[0]}>
                      {renderYears(5, 40)}
                    </select>
                  </div>
                </div>
                <div className="loan-cell loan-mixed">
                  <div className="col-6">
                    <div className="select-wrapper">
                      <select onChange={this.onSelectChange.bind(this, 'multiLoanLength', 3)} value={this.props.terms.multiLoanLength[this.props.loanOptions.primaryLoans.length]}>
                        {renderYears(5, 40)}
                      </select>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="select-wrapper">
                      <select onChange={this.onSelectChange.bind(this, 'multiLoanLength', 4)} value={this.props.terms.multiLoanLength[this.props.loanOptions.primaryLoans.length + 1]}>
                        {renderYears(5, 40)}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="loan-cell loan-nonindexed">
                  <div className="select-wrapper">
                    <select onChange={this.onSelectChange.bind(this, 'multiLoanLength', 2)} value={this.props.terms.multiLoanLength[2]}>
                      {renderYears(5, 40)}
                    </select>
                  </div>
                </div>
              </div>
              <div className="loan-row">
                <div className="loan-cell loan-label">Vextir</div>
                <div className="loan-cell loan-indexed">
                  {!this.props.terms.overrideInterests ?
                    this.props.type === "nonIndexed" ?
                      <div className="select-wrapper-no-select">
                        {formatPercentage(this.props.loan.interest_rate * 100)}% <br /> {this.props.loan.variable_interests ? 'breytilegir' : 'fastir'}
                      </div>
                      :
                      <div className="select-wrapper">
                        <select onClick={stopLabelPropagation.bind(this)} onChange={this.onSelectChange.bind(this, 'selectedIndexedLoanIndex', 0)} value={this.props.terms.selectedIndexedLoanIndex}>
                          {renderInterestOptions(this.props.loanOptions.primaryLoansIndex, [["custom", ""]])}
                        </select>
                      </div>
                    :
                    <div className="select-wrapper-no-select">
                      {formatPercentage(this.props.terms.overrideInterestsValues * 100)}%
                  </div>
                  }
                </div>
                <div className="loan-cell loan-mixed">
                  
                      <div className="col-6">
                        <div className="select-wrapper mb0">
                          <select className='two-lines' onClick={stopLabelPropagation.bind(this)} onChange={this.onSelectChange.bind(this, 'selectedIndexedLoanIndex2', 3)} value={this.props.terms.selectedIndexedLoanIndex2}>
                            {renderInterestOptions(this.props.loanOptions.primaryLoansIndex)}
                          </select>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="select-wrapper no-select mb0">
                          {formatPercentage(loanNonIndexed.interest_rate * 100)}% {loanNonIndexed.variable_interests ? 'breytilegir' : 'fastir'}
                        </div>
                      </div>
                    
                </div>
                <div className="loan-cell loan-nonindexed">
                  {!this.props.terms.overrideInterests ?
                    true ?
                      <div className="select-wrapper-no-select">
                        {formatPercentage(loanNonIndexed.interest_rate * 100)}% <br /> {loanNonIndexed.variable_interests ? 'breytilegir' : 'fastir'}
                      </div>
                      :
                      <div className="select-wrapper">
                        <select onClick={stopLabelPropagation.bind(this)} onChange={this.onSelectChange.bind(this, 'selectedIndexedLoanIndex', 2)} value={this.props.terms.selectedIndexedLoanIndex}>
                          {renderInterestOptions(this.props.loanOptions.primaryLoansIndex, [["custom", ""]])}
                        </select>
                      </div>
                    :
                    <div className="select-wrapper-no-select">
                      {formatPercentage(this.props.terms.overrideInterestsValues * 100)}%
                  </div>
                  }
                </div>
              </div>
              <div className="loan-row">
                <div className="loan-cell loan-label">Greiðslu&shy;fyrirkomulag</div>
                <div className="loan-cell loan-indexed">
                  <div className="select-wrapper">
                    <select onChange={this.onSelectChange.bind(this, 'multiLoanTypes', this.props.terms.selectedIndexedLoanIndex)} value={this.props.terms.multiLoanTypes[this.props.terms.selectedIndexedLoanIndex]}>
                      <option value="annuitet">Jafnar greiðslur</option>
                      <option value="regular">Jafnar afborganir</option>
                    </select>
                  </div>
                </div>
                <div className="loan-cell loan-mixed">
                  <div className="col-6">
                    <div className="select-wrapper">
                      <select className='two-lines' onChange={this.onSelectChange.bind(this, 'multiLoanTypes', 3)} value={this.props.terms.multiLoanTypes[this.props.loanOptions.primaryLoans.length]}>
                        <option value="annuitet">Jafnar greiðslur</option>
                        <option value="regular">Jafnar afborganir</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="select-wrapper">
                      <select className='two-lines' onChange={this.onSelectChange.bind(this, 'multiLoanTypes', 4)} value={this.props.terms.multiLoanTypes[this.props.loanOptions.primaryLoans.length + 1]}>
                        <option value="annuitet">Jafnar greiðslur</option>
                        <option value="regular">Jafnar afborganir</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="loan-cell loan-nonindexed">
                  <div className="select-wrapper">
                    <select onChange={this.onSelectChange.bind(this, 'multiLoanTypes', 2)} value={this.props.terms.multiLoanTypes[2]}>
                      <option value="annuitet">Jafnar greiðslur</option>
                      <option value="regular">Jafnar afborganir</option>
                    </select>
                  </div>
                </div>
              </div>


              <LoanPaymentSummary loans={loans} {...this.props} drawer={(window.md.tablet() || window.md.phone()) ? "open" : "closed"} />

              <LoanCostSummary loans={loans} {...this.props} drawer={(window.md.tablet() || window.md.phone()) ? "open" : "closed"} />




              <div className="loan-row border">
                <div className="loan-cell loan-label">Árleg hlutfallstala kostnaðar</div>
                <div className="loan-cell loan-indexed">
                  <strong>{formatPercentage(100 * loanIndexed.average_yearly_cost)}</strong> %
              </div>
                <div className="loan-cell loan-mixed">
                  <div className="row">
                    <div className="col-6"><strong>{formatPercentage(100 * this.props.multiLoan.loans[this.props.loanOptions.primaryLoans.length].average_yearly_cost)}</strong> %</div>
                    <div className="col-6"><strong>{formatPercentage(100 * this.props.multiLoan.loans[this.props.loanOptions.primaryLoans.length + 1].average_yearly_cost)}</strong> %</div>
                  </div>
                </div>
                <div className="loan-cell loan-nonindexed">
                  <strong>{formatPercentage(100 * loanNonIndexed.average_yearly_cost)}</strong> %
            </div>
              </div>
              <LoanPaymentsCharts {...this.props} drawer="open" disableDrawer={true} />
            </div>


            <LoanPaymentsTable {...this.props} drawer="open" disableDrawer={true} activeLoanClass={this.state.activeLoanClass} />


          </>

        }

      </div>);
  }
}
