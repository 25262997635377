import React from 'react';

class DisclaimerModal extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
        }
    }

    render() {
        return (
            <div className="modal" tabIndex="-1" role="dialog" aria-labelledby={this.props.modalId}
                aria-hidden="true" id={this.props.modalId} data-backdrop="false">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2>Lagalegur fyrirvari</h2>
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                        </div>
                        <div className="modal-body">
                            <div className="legal-disclaimer-body" dangerouslySetInnerHTML={{ __html: this.props.legalDisclaimer }}></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


DisclaimerModal.defaultProps = {
}


export default DisclaimerModal;