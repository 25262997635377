import { createStore, applyMiddleware, combineReducers } from 'redux'
import * as reducers from './redux/reducers'
import thunkMiddleware from 'redux-thunk'
import {createLogger} from 'redux-logger'
//import { SET_PICKUP_SHOP_WITH_REDIRECT } from './redux/actions'

const loggerMiddleware = createLogger()

var finalCreateStore = applyMiddleware(thunkMiddleware, loggerMiddleware)(createStore);
//var finalCreateStore = applyMiddleware(thunkMiddleware)(createStore);
const store = finalCreateStore(combineReducers(reducers));

export default store
