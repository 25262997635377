import React from 'react';
import LoanHeaderMore from './loan-header-more';

class LoanSettingsModal extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        console.log("settings modal props", this.props)
        return (
            <div className="modal" tabIndex="-1" role="dialog" aria-labelledby="loanSettingsModal"
                aria-hidden="true" id="loanSettingsModal" data-backdrop="false">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                        </div>
                        <div className="modal-body">
                            <LoanHeaderMore {...this.props} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


LoanSettingsModal.defaultProps = {
}


export default LoanSettingsModal;