import React from 'react';
import ReactDOM from 'react-dom';
import { loan, maxLoan, buildParams, formatISK } from './utils';
import { setState, fetchLoans } from '../../redux/actions';
import asDrawer from './drawer';
import DisclaimerModal from './disclaimer-modal'


window.ReactDOM = ReactDOM

class LoanDisclaimer extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (<div className="loan-table-header row clearfix">
      <div className="col-sm-12 legal-disclaimer disclaimer-btn"><a className={this.props.drawer} href="#" data-toggle="modal" data-target="#loanDisclaimerModal">Lagalegur fyrirvari</a></div>
      <DisclaimerModal legalDisclaimer={this.props.legalDisclaimer} modalId="loanDisclaimerModal"/>
    </div>);
  }
}

export default asDrawer(LoanDisclaimer);
