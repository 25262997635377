var React = require('react')
var ReactDOM = require('react-dom')
var $ = require('jquery')
import LoanCalculator from './loan-calculator';
import store from '../../store'


$.fn.LoanCalculator = function() {
  return this.each(function() {
    var options = $(this).data();
    options.store = store;
    ReactDOM.render(React.createElement(LoanCalculator, options), this);
  });
};

/**
 * Initialize Data Attribute
 */

$(document).ready(function() {
  $('[data-loan-calculator]').LoanCalculator();
});
