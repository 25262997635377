import React from 'react';
import ReactDOM from 'react-dom';
import { formatISK, fractionalSkipRatio, getLoanTypeFromMultiloanIndex, findGetParameter, calculateSelectedPayments } from './utils';
import { setState, fetchLoans, setLoanType } from '../../redux/actions';
import asDrawer from './drawer';
import moment from 'moment'
import 'chart.js/auto';
import { Chart as ChartJS } from 'chart.js'
import { Chart } from 'react-chartjs-2';
import ChartjsPluginStacked100 from "chartjs-plugin-stacked100";

// Chart.register({
//   afterUpdate: fractionalSkipRatio,
// });
// Chart.defaults.global.legend.position = 'bottom';


const GRAPH_FACTOR = 2;

ChartJS.register(ChartjsPluginStacked100);

class LoanPaymentsCharts extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  renderGraphs(loans, type = 'monthly_payments') {

    /*
      Type one of:
       - monthly_payments
       - capital_evolution
    */
    var selectedPayments = calculateSelectedPayments(loans)
    if (this.props.drawer === 'open') {
      var legend = { position: 'bottom' }
      
      var axisOptions = {
        maintainAspectRatio: true,
        responsive: true,
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                return `${context.dataset.label}: ${formatISK(context.raw)} kr.`;
              },
              title: function (context) {
                return "Árið " + context[0].label;
              }
            }
          }
        },
        scales: {
          x: {
            // gridLines: {
            //   display: false
            // },
            ticks: {
              autoSkip: true,
              maxTicksLimit: 7
            }
          },
          y: {
            stacked: false,
            ticks: {
              callback: function (label, index, labels) {
                if (labels[1].value > 1000000) {
                  return label / 1000000 + 'm kr';
                }
                else if (labels[1].value > 1000 && labels[1].value <= 1000000) {
                  return label / 1000 + 'þ kr';
                }
                else {
                  return label + '%'
                }
              }
            }
          }
        }
      };

      var axisOptionsPercentage = {
        // plugins: [{
        //   stacked100: { enable: true, replaceTooltipLabel: false }
        // }],
        plugins: {
          stacked100: { enable: true, replaceTooltipLabel: false },
          tooltip: {
            callbacks: {
              label: function (context) {
                const datasetIndex = context.datasetIndex;
                const datasetLabel = context.dataset.label;
                // You can use two type values.
                // `data.originalData` is raw values,
                // `data.calculatedData` is percentage values, e.g. 20.5 (The total value is 100.0)
                const originalValue = context.raw;
                const rateValue = context.formattedValue;
                return `${datasetLabel}: ${rateValue}% (${formatISK(originalValue)} kr.)`;
  
              },
              title: function (context) {
                return "Árið " + context[0].label;
              }
            }
          },
        },
        maintainAspectRatio: true,
        responsive: true,
        scales: {
          x: {
            // gridLines: {
            //   display: false
            // },
            ticks: {
              autoSkip: true,
              maxTicksLimit: 7
            }
          },
          y: {
            stacked: true,
            ticks: {
              callback: function (label, index, labels) {
                if (labels[0] > 1000000) {
                  return label / 1000000 + 'm kr';
                }
                else if (labels[0] > 1000 && labels[0] <= 1000000) {
                  return label / 1000 + 'þ kr';
                }
                else {
                  return label + '%'
                }
              }
            }
          }
        }
      }


      let graphClassNames = "col-12"

      if (type === 'monthly_payments') {

        return (<div className="row mb20 chart-container">
          <div className={graphClassNames}>
            <Chart type='line' height={250} redraw={this.props.loan.redraw} data={{
              labels: selectedPayments.map((payment, i) => {
                if (i % GRAPH_FACTOR == 0)
                  return moment().add(i + 1, 'M').startOf('month').format('YYYY');
              }).filter(function (element) { return element !== undefined; }),
              datasets: [
                {
                  label: 'Afborgun',
                  fill: true,
                  backgroundColor: 'rgba(236, 181, 19, 0.5)',
                  borderWidth: 2,
                  pointRadius: 0,
                  lineTension: 1,
                  pointHitRadius: 4,
                  cubicInterpolationMode: 'monotone',
                  data: selectedPayments.map((payment, i) => {
                    if (i % GRAPH_FACTOR == 0) {
                      return payment.principal_payment;
                    }
                  }).filter(function (element) { return element !== undefined; })
                },
                {
                  label: 'Greiðsla alls',
                  fill: true,
                  backgroundColor: 'rgba(236, 181, 19, 0.2)',
                  borderColor: 'rgba(236, 181, 19, 0.2)',
                  borderWidth: 2,
                  pointRadius: 0,
                  lineTension: 1,
                  pointHitRadius: 4,
                  cubicInterpolationMode: 'monotone',
                  data: selectedPayments.map((payment, i) => {
                    if (i % GRAPH_FACTOR == 0) {
                      return payment.payment;
                    }
                  }).filter(function (element) { return element !== undefined; })
                }
              ]
            }} legend={legend} options={axisOptions} />
          </div></div>)
      }
      else if (type === 'capital_evolution') {
        return (<div className="row mb20 chart-container">
          <div className={graphClassNames}>
            <Chart type='line' height={250} redraw={this.props.loan.redraw} data={{
              labels: selectedPayments.map((payment, i) => {
                if (i % GRAPH_FACTOR == 0)
                  return moment().add(i + 1, 'M').startOf('month').format('YYYY');
              }).filter(function (element) { return element !== undefined; }),
              datasets: [
                {
                  label: 'Höfuðstóll',
                  fill: true,
                  backgroundColor: 'rgba(236, 181, 19, 0.5)',
                  borderColor: 'rgba(236, 181, 19, 0.5)',
                  borderWidth: 2,
                  pointRadius: 0,
                  lineTension: 1,
                  pointHitRadius: 4,
                  cubicInterpolationMode: 'monotone',
                  data: selectedPayments.map((payment, i) => {
                    if (i % GRAPH_FACTOR == 0) {
                      return payment.balance
                    }
                  }).filter(function (element) { return element !== undefined; })
                }
              ]
            }} options={axisOptions} />
          </div>
        </div>)
      } else if (type == 'asset_buildup') {
        return (
          <div className="row mb20 chart-container">
            <div className={graphClassNames}>
              <Chart type='line' height={250} redraw={this.props.loan.redraw} data={{
                labels: selectedPayments.map((payment, i) => {
                  if (i % GRAPH_FACTOR == 0)
                    return moment().add(i + 1, 'M').startOf('month').format('YYYY');
                }).filter(function (element) { return element !== undefined; }),
                datasets: [
                  {
                    label: 'Skuld',
                    fill: true,
                    backgroundColor: 'rgba(236, 181, 19, 0.5)',
                    borderWidth: 2,
                    pointRadius: 0,
                    lineTension: 1,
                    pointHitRadius: 4,
                    cubicInterpolationMode: 'monotone',
                    data: selectedPayments.map((payment, i) => {
                      if (i % GRAPH_FACTOR == 0) {
                        return payment.balance;
                      }
                    }).filter(function (element) { return element !== undefined; })
                  },
                  {
                    label: 'Eign',
                    fill: true,
                    backgroundColor: 'rgba(236, 181, 19, 0.2)',
                    borderColor: 'rgba(236, 181, 19, 0.2)',
                    borderWidth: 2,
                    pointRadius: 0,
                    lineTension: 1,
                    pointHitRadius: 4,
                    cubicInterpolationMode: 'monotone',
                    data: selectedPayments.map((payment, i) => {
                      if (i % GRAPH_FACTOR == 0) {
                        return payment.market_price_adjusted - payment.balance;
                      }
                    }).filter(function (element) { return element !== undefined; })
                  }
                ]
              }} legend={legend} options={axisOptionsPercentage} />
            </div>
          </div>)
      }
    }
  }

  getCurrentLoan(i, graph_type = "monthly_payments") {
    let loans = []
    if (i >= this.props.loanOptions.primaryLoans.length) {
      loans = [this.props.multiLoan.loans[i], this.props.multiLoan.loans[i+1]]
    } else {
      loans = [this.props.multiLoan.loans[i]];
    }    
    return (
      <div className={`loan-table clearfix ${this.props.drawer}`}>
        {this.renderGraphs(loans, graph_type)}
      </div>
    )
  }

  render() {

    let testing = findGetParameter("test")
    let i = 0;

    let loanIndices = [0, 3, 2]

    return (
      <>
        <div className={`loan-row ${this.props.multiLoan.isFetching ? 'fetching' : ''}`}>
          <div className="loan-cell loan-label">
            Mánaðarleg greiðsla
          </div>

          {this.props.multiLoan.loanDataReceived &&
            <>
              {loanIndices.map(i =>
                <div className={`loan-cell loan-${getLoanTypeFromMultiloanIndex(i)}`}>
                  {this.getCurrentLoan(i, "monthly_payments")}
                </div>
              )}
            </>
          }
        </div>
        <div className="loan-row">
          <div className="loan-cell loan-label">
            Lækkun höfuðstóls
          </div>
          {this.props.multiLoan.loanDataReceived &&
            <>
              {loanIndices.map(i =>
                <div className={`loan-cell loan-${getLoanTypeFromMultiloanIndex(i)}`}>
                  {this.getCurrentLoan(i, "capital_evolution")}
                </div>
              )}
            </>
          }
        </div>
        <div className="loan-row">
          <div className="loan-cell loan-label">
            Eignamyndun <br /><i>(Gert er ráð fyrir að markaðsverð hækki að raunvirði um {parseInt(this.props.terms.marketPriceIncrease*100)}% á ári yfir lánstímann.)</i>
          </div>
          {this.props.multiLoan.loanDataReceived &&
            <>
              {loanIndices.map(i =>
                <div className={`loan-cell loan-${getLoanTypeFromMultiloanIndex(i)}`}>
                  {this.getCurrentLoan(i, "asset_buildup")}
                </div>
              )}
            </>
          }
        </div>
        <div className="loan-row">
          <div className="loan-cell loan-label"></div>
          {this.props.multiLoan.loanDataReceived &&
            <>
              {loanIndices.map(i =>
                <div className={`loan-cell loan-${getLoanTypeFromMultiloanIndex(i)}`}>
                </div>
              )}
            </>
          }

        </div>

      </>
    )


  }
}


export default asDrawer(LoanPaymentsCharts);
