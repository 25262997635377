require('es6-promise').polyfill();
import fetch from 'isomorphic-fetch'
import moment from 'moment'
import $ from 'jquery'

export const REQUEST_LOANS = 'REQUEST_LOANS';
export const FETCH_LOANS = 'FETCH_LOANS';
export const RECEIVE_LOANS = 'RECEIVE_LOANS';
export const REQUEST_MULTI_LOANS = 'REQUEST_MULTI_LOANS';
export const FETCH_MULTI_LOANS = 'FETCH_MULTI_LOANS';
export const RECEIVE_MULTI_LOANS = 'RECEIVE_MULTI_LOANS';
export const SET_STATE = 'SET_STATE';
export const SET_STATE_MULTI = 'SET_STATE_MULTI';
export const SET_LOAN_TYPE = 'SET_LOAN_TYPE';
export const DISABLE_REDRAW = 'DISABLE_REDRAW';
export const SET_LOAN_OPTIONS = 'SET_LOAN_OPTIONS';


export const setState = (params) => {
  return {
    type: SET_STATE,
    data: params
  }
}

export const setMultiState = (params) => {
  return {
    type: SET_STATE_MULTI,
    data: params
  }
}

export const disableRedraw = () => {
  return {
    type: DISABLE_REDRAW,
  }
}


export const setLoanType = (value) => {
  return {
    type: SET_LOAN_TYPE,
    data: value
  }
}

export const setLoanOptions = (value) => {
  return {
    type: SET_LOAN_OPTIONS,
    data: value
  }
}

const requestLoans = (params) => {
  return {
    type: REQUEST_LOANS,
    params: params
  }
}

const receiveLoans = (json) => {
  return {
    type: RECEIVE_LOANS,
    data: json,
    receivedAt: moment()
  }
}

export const fetchLoans = (params={}) => {
  return dispatch => {
    dispatch(requestLoans(params))
    return fetch(`/api/loan/?${$.param(params)}`)
      .then(response => response.json())
      .then(json => dispatch(receiveLoans(json)))
      .then(response => dispatch(disableRedraw()))
  }
}

const requestMultiLoans = (params) => {
  return {
    type: REQUEST_MULTI_LOANS,
    params: params
  }
}

const receiveMultiLoans = (json) => {
  return {
    type: RECEIVE_MULTI_LOANS,
    data: json,
    receivedAt: moment()
  }
}

export const fetchMultiLoans = (params={}) => {
  return dispatch => {
    dispatch(requestMultiLoans(params))
    return fetch(`/api/multi_loan/?${$.param(params)}`)
      .then(response => response.json())
      .then(json => dispatch(receiveMultiLoans(json)))
  }
}
