import React from 'react';
import { connect } from 'react-redux'
import LoanHeader from './loan-header';
import LoanHeaderMore from './loan-header-more';
import LoanPaymentSummary from './loan-payment-summary';
import LoanCostSummary from './loan-cost-summary';
import LoanOverview from './loan-overview';
import { setState, fetchLoans, setLoanOptions } from '../../redux/actions';
import { maxLoan, formatISK, formatPercentage, buildParams, renderYears, renderInflationOptions, renderPaymentFees } from './utils';
var Promise = require('es6-promise').Promise;

class LoanCalculator extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.dispatch(setState({ key: 'maxLoanPercentage', value: this.props.maxLoanPercentage / 100 }))
    this.props.dispatch(setState({ key: 'primaryLoanPercentage', value: this.props.primaryLoanPercentage / 100 }))
    this.props.dispatch(setState({ key: 'marketPrice', value: this.props.marketPriceDefault }))
    this.props.dispatch(setState({ key: 'marketPriceIncrease', value: "0.01" }))
    this.props.dispatch(setState({ key: 'loanAmount', value: this.props.loanDefault }))
    this.props.dispatch(setState({ key: 'maxLoanAmount', value: this.props.maxLoanAmount }))
    //this.props.dispatch(setState({key: 'multiLoanLength', value: 40}))
    this.props.dispatch(setLoanOptions(this.props.loanOptionsInitial))
    //this.props.dispatch(fetchLoans(buildParams(this.props.terms)))
  }

  // componentDidUpdate() {
  //   $('[data-toggle="tooltip"]').tooltip();
  // }

  renderLoanType() {
    switch (this.props.terms.selectedLoanIndex) {
      case 0:
      case 1:
        return 'Verðtryggt lán';
      case 2:
        return 'Óverðtryggt lán';
      case 3:
        return 'Blandað lán';
    }
  }

  render() {
    var maxLoanAmount = maxLoan(parseInt(this.props.terms.marketPrice), this.props.terms.maxLoanPercentage * 100, this.props.terms.maxLoanAmount)
    var showResults = (this.props.terms.loanAmount <= maxLoanAmount)

    return (<div className="row">
      <div className="loan-calc-header">Lánareiknir</div>
      <LoanHeader {...this.props} />
      {showResults &&
        <LoanOverview {...this.props} />
      }
    </div>
    );
  }

};

function mapStateToProps(state) {

  var f = { loan: {}, terms: {} }
  f.loan = (state.loan === undefined) ? {} : state.loan
  f.multiLoan = (state.multiLoan === undefined) ? {} : state.multiLoan
  f.loanOptions = (state.loanOptions === undefined) ? {} : state.loanOptions
  f.terms = (state.terms === undefined) ? {} : state.terms
  return f;
}

export default connect(mapStateToProps)(LoanCalculator);
