import React from 'react';
import ReactDOM from 'react-dom';
import { loan, maxLoan, buildParams, formatISK, getSelectedLoans, getLoanTypeFromIndex } from './utils';
import { setState, fetchLoans } from '../../redux/actions';
import asDrawer from './drawer';


window.ReactDOM = ReactDOM

class LoanCostSummary extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {


    let loanIndexed = this.props.multiLoan.loans[this.props.terms.selectedIndexedLoanIndex];
    let index = this.props.loanOptions.primaryLoansIndex ? this.props.loanOptions.primaryLoansIndex.length : 2;
    let loanNonIndexed = this.props.multiLoan.loans[index]
    let loansMixed = [this.props.multiLoan.loans[this.props.loanOptions.primaryLoans.length], this.props.multiLoan.loans[this.props.loanOptions.primaryLoans.length + 1]]

    const { loans } = this.props

    return (

      <>

      

        <div className={`loan-row-header  ${this.props.drawer}`} >
          <div className="loan-cell loan-label" onClick={this.props.onDrawerToggle}>Kostnaður</div>

          {this.props.drawer === "closed" ?
            <>
              {loans.map((loan, i) =>
                <>
                  {getLoanTypeFromIndex(i) === "mixed" ?
                    <div className={`loan-cell loan-${getLoanTypeFromIndex(i)}`}>
                      {formatISK(loan[0].total_fees + loan[1].total_fees + loan[0].total_slip_fee + loan[1].total_slip_fee)} kr.
                      </div>
                    : 
                    <div className={`loan-cell loan-${getLoanTypeFromIndex(i)}`}>
                      {formatISK(loan.total_fees+loan.total_slip_fee)} kr.
                      </div>
                  }
                </>

              )}
            </>
            :
            <>
              <div className="loan-cell"></div>
              <div className="loan-cell"></div>
              <div className="loan-cell"></div>
            </>
          }
        </div>



        {this.props.drawer === "open" &&

          <>

            <div className="loan-row border">
              <div className="loan-cell loan-label">Lántökugjald</div>
              {loans.map((loan, i) =>
                <>
                  {getLoanTypeFromIndex(i) === "mixed" ?
                    <>
                      <div className={`loan-cell loan-${getLoanTypeFromIndex(i)}`}>
                        <div className="row">
                          <div className="col-6">{formatISK(loan[0].total_fees)} kr.</div>
                          <div className="col-6">{formatISK(loan[1].total_fees)} kr.</div>
                        </div>
                      </div>
                    </>
                    :
                    <div className={`loan-cell loan-${getLoanTypeFromIndex(i)}`}>{formatISK(loan.total_fees)} kr.</div>
                  }
                </>
              )}
            </div>
            <div className="loan-row border">
              <div className="loan-cell loan-label">Greiðslugjald</div>
              {loans.map((loan, i) =>
                <>
                  {getLoanTypeFromIndex(i) === "mixed" ?
                    <>
                      <div className={`loan-cell loan-${getLoanTypeFromIndex(i)}`}>
                        <div className="row">
                          <div className="col-6">{formatISK(loan[0].total_slip_fee)} kr.</div>
                          <div className="col-6">{formatISK(loan[1].total_slip_fee)} kr.</div>
                        </div>
                      </div>
                    </>
                    :
                    <div className={`loan-cell loan-${getLoanTypeFromIndex(i)}`}>{formatISK(loan.total_slip_fee)} kr.</div>
                  }
                </>
              )}
            </div>

            <div className="loan-row border">
              <div className="loan-cell loan-label">Heildarkostnaður</div>
              {loans.map((loan, i) =>
                <>
                  {getLoanTypeFromIndex(i) === "mixed" ?
                    <>
                      <div className={`loan-cell loan-${getLoanTypeFromIndex(i)}`}>
                        <div className="row">
                          <div className="col-6"><strong>{formatISK(loan[0].total_slip_fee+loan[0].total_fees)}</strong> kr.</div>
                          <div className="col-6"><strong>{formatISK(loan[1].total_slip_fee+loan[1].total_fees)}</strong> kr.</div>
                        </div>
                      </div>
                    </>
                    :
                    <div className={`loan-cell loan-${getLoanTypeFromIndex(i)}`}><strong>{formatISK(loan.total_slip_fee+loan.total_fees)}</strong> kr.</div>
                  }
                </>
              )}
            </div>


          </>
        }
      </>
    )
  }
}

export default asDrawer(LoanCostSummary);
