import { combineReducers } from 'redux'
import moment from 'moment'
import {convertToHumanReadablePercentage} from '../components/loan_calculator/utils'

import {
  REQUEST_LOANS,
  FETCH_LOANS,
  RECEIVE_LOANS,
  REQUEST_MULTI_LOANS,
  FETCH_MULTI_LOANS,
  RECEIVE_MULTI_LOANS,
  SET_STATE,
  SET_STATE_MULTI,
  SET_LOAN_TYPE,
  SET_LOAN_OPTIONS,
  DISABLE_REDRAW
} from './actions'

const _defaultLoanState = {
  isFetching: false,
  loanDataReceived: false,
  paymentsToShow: 'combined', // other options: primary, secondary
  selectedPayments: [],
  data: {
    loans: [],
    primaryLoan: {average_yearly_cost: 0},
    secondaryLoan: null
  },
  redraw: false,
}

const _defaultMultiLoanState = {
  isFetching: false,
  loanDataReceived: false,
  loans: [],
}

const _defaultTermsState = {
  marketPrice: 30000000,
  marketPriceIncrease: 0.01,
  loanAmount: 20000000,
  primaryLoanType: 'annuitet',
  secondaryLoanType: 'annuitet',
  maxLoanPercentage: 0.75,
  primaryLoanPercentage: 0.65,
  primaryLoanLength: 40,
  secondaryLoanLength: 25,
  primaryInterestRate: 0.061,
  secondaryInterestRate: 0.066,
  primaryLoanIndexed: true,
  secondaryLoanIndexed: false,
  primaryInflationForecast: 0.0191,
  secondaryInflationForecast: 0.0191,
  primaryPaymentFee: 0,
  secondaryPaymentFee: 0,
  selectedLoanIndex: 0,
  selectedIndexedLoanIndex: 0,
  selectedIndexedLoanIndex2: 0,
  multiLoanTypes: [
    'annuitet',
    'annuitet',
    'annuitet',
    'annuitet',
    'annuitet',
  ],
  multiLoanLength: [
    '40',
    '40',
    '40',
    '40',
    '40',
  ],
  loanSplit: 50,
  buttonClicked: false,
  customInflationForecast: 0,
  overrideInterests: false,
  overrideInterestsValues: 0,
  overrideInterestsShowInput: false,
}

const _defaultLoanOptionsState = {
  primaryLoans: [],
  secondaryLoans: [],
  inflationForecasts: []
}

export function loan(state=_defaultLoanState, action) {
  switch (action.type){
    case DISABLE_REDRAW:
      state = {...state,
        redraw: false,
      }
      return state;
    case REQUEST_LOANS:
      state = {...state,
        isFetching: true,
        redraw: false,
      }
      return state;
    case SET_LOAN_TYPE:
      state = {...state,
        paymentsToShow: action.data,
        redraw: false,
      }
      switch(state.paymentsToShow){
        case 'combined':
          if (state.data.loans.length === 1){
            state.selectedPayments = state.data.primaryLoan.payments;
          } else {
            state.selectedPayments = state.data.payments;
          }
          break;
        case 'primary':
          state.selectedPayments = state.data.primaryLoan.payments;
          break;
        case 'secondary':
          state.selectedPayments = state.data.secondaryLoan.payments;
          break;
      }

      return state;
    case RECEIVE_LOANS:
      let data = action.data;
      data.primaryLoan = action.data.loans[0];
      if (action.data.loans.length > 1)
        data.secondaryLoan = action.data.loans[1];
      else
        data.secondaryLoan = null;
      state = {...state,
        isFetching: false,
        loanDataReceived: true,
        data: data,
        redraw: true
      }
      switch(state.paymentsToShow){
        case 'combined':
          if (action.data.loans.length === 1){
            state.selectedPayments = state.data.primaryLoan.payments;
          } else {
            state.selectedPayments = state.data.payments;
          }
          break;
        case 'primary':
          state.selectedPayments = state.data.primaryLoan.payments;
          break;
        case 'secondary':
          state.selectedPayments = state.data.secondaryLoan.payments;
          break;
      }
      return state;
    default:
      return state;
  }
}

export function multiLoan(state=_defaultMultiLoanState, action) {
  switch (action.type){
    case REQUEST_MULTI_LOANS:
      state = {...state,
        isFetching: true,
        redraw: false,
      }
      return state;

    case RECEIVE_MULTI_LOANS:
      let data = action.data;
      state = {...state,
        isFetching: false,
        loanDataReceived: true,
        loans: data.loans,
      }
    default:
      return state;
  }
}

export function terms(state=_defaultTermsState, action) {
  switch (action.type){
    case SET_STATE:
      state = {...state}
      state[action.data.key] = action.data.value;
      if (state.selectedLoanIndex === 3 && state.loanAmount < 2000000) {
        state.selectedLoanIndex = 0;
      }
      let vt = Math.round(state.loanAmount * state.loanSplit/100)
      let ovt = Math.round(state.loanAmount * (1-state.loanSplit/100))
      if (vt < 1000000 || ovt < 1000000) {
        state.loanSplit = 50;
      }

      return state;
    case SET_STATE_MULTI:
      state = {...state}
      for(let item of action.data) {
        state[item.key] = item.value
      }
      return state;
    case SET_LOAN_OPTIONS:
      state = {...state}
        try {state.primaryInterestRate = action.data.primaryLoans.filter((d) => !d.indexed )[0].value/100.0} catch (e) {}
        try {state.secondaryInterestRate = action.data.secondaryLoans.filter((d) => !d.indexed )[0].value/100.0} catch (e) {}
        try {state.primaryInflationForecast = action.data.inflationForecasts[0].value} catch (e) {}
        try {state.secondaryInflationForecast = action.data.inflationForecasts[0].value} catch (e) {}
        try {state.primaryPaymentFee = action.data.paymentFees[0].value} catch (e) {}
        try {state.secondaryPaymentFee = action.data.paymentFees[0].value} catch (e) {}
        try {state.multiLoanInflationForecast = action.data.inflationForecasts[0].value} catch (e) {}
        try {state.customInflationForecast = (""+action.data.inflationForecasts[0].value).replace(".", ",")} catch (e) {}
        try {state.multiLoanPaymentFee = action.data.paymentFees[0].value} catch (e) {}

        
        return state
    default:
      return state;
  }
}

export function loanOptions(state=_defaultLoanOptionsState, action) {
  switch (action.type){
    case SET_LOAN_OPTIONS:
      state = {...state}
      state.primaryLoans = action.data.primaryLoans.map((d) => {d.value = d.value; return d;});
      state.primaryLoansIndex = action.data.primaryLoans.filter((d) => d.indexed ).map((d) => {d.value = d.value/100.0; return d;})
      state.primaryLoansNoIndex = action.data.primaryLoans.filter((d) => !d.indexed ).map((d) => {d.value = d.value/100.0; return d;})
      state.secondaryLoansIndex = action.data.secondaryLoans.filter((d) => d.indexed ).map((d) => {d.value = d.value/100.0; return d;})
      state.secondaryLoansNoIndex = action.data.secondaryLoans.filter((d) => !d.indexed ).map((d) => {d.value = d.value/100.0; return d;})
      state.inflationForecasts = action.data.inflationForecasts.map((d) => {d.value = d.value/100.0; return d;});
      state.paymentFees = action.data.paymentFees;
      return state;
    default:
      return state;
  }
}
