import React from 'react';
import ReactDOM from 'react-dom';
import { formatISK, fractionalSkipRatio, getLoanTypeFromMultiloanIndex, calculateSelectedPayments } from './utils';
import { setState, fetchLoans, setLoanType } from '../../redux/actions';
import asDrawer from './drawer';
import moment from 'moment'

class LoanPaymentsTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showAll: false,
      activePaymentTable: null,
    }
    this.showAllPayments = this.showAllPayments.bind(this);
    this.onSetLoanType = this.onSetLoanType.bind(this);
  }

  showAllPayments() {
    this.setState({
      showAll: true,
    })
  }

  onSetLoanType(value) {
    this.props.dispatch(setLoanType(value));
  }

  hasIndexedLoan(loans) {
    return ((loans.length === 1 && loans[0].indexed) ||
      (loans.length === 2 && loans[0].indexed && loans[1].indexed))
  }


  renderPaymentsTable() {

    let loans = []
    if (this.state.activePaymentTable >= this.props.loanOptions.primaryLoans.length) {
      loans = [this.props.multiLoan.loans[this.state.activePaymentTable], this.props.multiLoan.loans[this.state.activePaymentTable + 1]]
    } else {
      loans = [this.props.multiLoan.loans[this.state.activePaymentTable + this.props.terms.selectedIndexedLoanIndex]];
    }

    var primaryLoanClasses = 'col-sm-4';
    var secondaryLoanClasses = 'col-sm-4';

    if (this.props.loan.data.loans.length == 1) {
      primaryLoanClasses = 'col-sm-8';
      secondaryLoanClasses = 'hidden';
    }

    var startDate = moment().add(1, 'M').startOf('month');
    var payments = []

    var selectedPayments = calculateSelectedPayments(loans)

    var header = (
      <thead>
        <tr>
          <th>Dags.</th>
          <th className="text-right">Afborgun</th>
          <th className="text-right">Vextir</th>
          <th className="text-right">Verðbætur</th>
          <th className="text-right">Greiðslugj.</th>
          <th className="text-right">Greitt samtals</th>
        </tr>
      </thead>);

    for (var i = 0; i < selectedPayments.length; i++) {
      if (!this.state.showAll && (i < 5 || i >= (selectedPayments.length - 5)) || this.state.showAll) {
        payments.push(
          <tr key={i}>
            <td>{moment().add(i + 1, 'M').startOf('month').format('DD.MM.YYYY')}</td>

            <td className="text-right">{formatISK(selectedPayments[i].principal_payment)} kr.</td>

            <td className="text-right">{formatISK(selectedPayments[i].interests)} kr.</td>


            {this.hasIndexedLoan(loans)
              ?
              <td className="text-right">{(selectedPayments[i].verdbaetur !== null && selectedPayments[i].verdbaetur !== undefined && selectedPayments[i].verdbaetur_v_vaxta !== null && selectedPayments[i].verdbaetur_v_vaxta !== undefined) ? formatISK(selectedPayments[i].verdbaetur + selectedPayments[i].verdbaetur_v_vaxta) : '-'} kr.</td>
              :
              <td className="text-right">-</td>
            }
            <td className="text-right">{formatISK(selectedPayments[i].slip)} kr.</td>
            <td className="text-right">{formatISK(selectedPayments[i].payment)} kr.</td>
          </tr>
        );
      }
      if (!this.state.showAll && i == 5) {
        payments.push(
          <tr key={999}>
            <td colSpan="6" className="center"><button className="btn btn-default birta-btn expand-payment-table" onClick={this.showAllPayments}>Birta alla gjalddaga</button></td>
          </tr>
        );
      }
    }

    if (this.props.drawer === 'open') {
      return (
        <div className="table-responsive">
          <table className="table mb0 loan-payments-table">
            {header}
            <tbody>
              {payments}
            </tbody>
          </table>
        </div>
      )
    }
  }

  togglePaymentTables(i) {
    console.log("togglepaymenttable", i)
    if (this.state.activePaymentTable === i) {
      this.setState({
        activePaymentTable: null,
      })
    } else {
      this.setState({
        activePaymentTable: i,
      })
    }

  }

  getCurrentLoan(i, graph_type = "monthly_payments") {
    let loans = []
    if (i >= this.props.loanOptions.primaryLoans.length) {
      loans = [this.props.multiLoan.loans[i], this.props.multiLoan.loans[i + 1]]
    } else {
      loans = [this.props.multiLoan.loans[i]];
    }
    return (
      <div className={`loan-table clearfix ${this.props.drawer}`}>
        {this.renderGraphs(loans, graph_type)}
      </div>
    )
  }

  render() {


    let i = 0;

    let loanIndices = [0, 3, 2]

    return (
      <>

        <div className={`loan-table payment-table open-${this.props.activeLoanClass}`}>
          <div className="loan-row">
            <div className="loan-cell loan-label"></div>
            {this.props.multiLoan.loanDataReceived &&
              <>
                {loanIndices.map(i =>
                  <div className={`loan-cell loan-${getLoanTypeFromMultiloanIndex(i)}`}>
                    <button className={`payment-table-button ${this.state.activePaymentTable === i ? "open" : ""}`} onClick={this.togglePaymentTables.bind(this, i)}>{this.state.activePaymentTable === i ? "Loka niðurgreiðslutöflu" : "Sýna niðurgreiðslutöflu"}</button>
                  </div>
                )}
              </>
            }

          </div>
        </div>

        {this.props.multiLoan.loanDataReceived && this.state.activePaymentTable !== null &&
          <>
            {this.renderPaymentsTable()}
          </>
        }
      </>
    )


  }
}


export default asDrawer(LoanPaymentsTable);
