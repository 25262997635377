import React from 'react';
import ReactDOM from 'react-dom';
import { loan, maxLoan, buildParams, formatISK, getSelectedLoans, getLoanTypeFromIndex } from './utils';
import { setState, fetchLoans } from '../../redux/actions';
import asDrawer from './drawer';


window.ReactDOM = ReactDOM

class LoanPaymentSummary extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {


    let loanIndexed = this.props.multiLoan.loans[this.props.terms.selectedIndexedLoanIndex];
    let index = this.props.loanOptions.primaryLoansIndex ? this.props.loanOptions.primaryLoansIndex.length : 2;
    let loanNonIndexed = this.props.multiLoan.loans[index]
    let loansMixed = [this.props.multiLoan.loans[this.props.loanOptions.primaryLoans.length], this.props.multiLoan.loans[this.props.loanOptions.primaryLoans.length + 1]]

    const { loans } = this.props

    return (

      <>

        <div className={`loan-row-header  ${this.props.drawer}`} >
          <div className="loan-cell loan-label" onClick={this.props.onDrawerToggle}>Greiðslur</div>

          {this.props.drawer === "closed" ?
            <>
              {loans.map((loan, i) =>
                <>
                  {getLoanTypeFromIndex(i) === "mixed" ?
                    <div className={`loan-cell underline loan-${getLoanTypeFromIndex(i)}`}>
                      <span>{formatISK(loan[0].total_paid + loan[1].total_paid)} kr.</span>
                      </div>
                    :
                    <div className={`loan-cell underline loan-${getLoanTypeFromIndex(i)}`}>
                      <span>{formatISK(loan.total_paid)} kr.</span>
                      </div>
                  }
                </>

              )}
            </>
            :
            <>
              <div className="loan-cell"></div>
              <div className="loan-cell"></div>
              <div className="loan-cell"></div>
            </>
          }
        </div>



        {this.props.drawer === "open" &&

          <>

            <div className="loan-row border">
              <div className="loan-cell loan-label">Höfuðstóll</div>
              {loans.map((loan, i) =>
                <>
                  {getLoanTypeFromIndex(i) === "mixed" ?
                    <>
                      <div className={`loan-cell loan-${getLoanTypeFromIndex(i)}`}>
                        <div className="row">
                          <div className="col-6">{formatISK(loan[0].principal)} kr.</div>
                          <div className="col-6">{formatISK(loan[1].principal)} kr.</div>
                        </div>
                      </div>
                    </>
                    :
                    <div className={`loan-cell loan-${getLoanTypeFromIndex(i)}`}>{formatISK(loan.principal)} kr.</div>
                  }
                </>
              )}
            </div>
            <div className="loan-row border">
              <div className="loan-cell loan-label">Vextir</div>
              {loans.map((loan, i) =>
                <>
                  {getLoanTypeFromIndex(i) === "mixed" ?
                    <>
                      <div className={`loan-cell loan-${getLoanTypeFromIndex(i)}`}>
                        <div className="row">
                          <div className="col-6">{formatISK(loan[0].total_interests)} kr.</div>
                          <div className="col-6">{formatISK(loan[1].total_interests)} kr.</div>
                        </div>
                      </div>
                    </>
                    :
                    <div className={`loan-cell loan-${getLoanTypeFromIndex(i)}`}>{formatISK(loan.total_interests)} kr.</div>
                  }
                </>

              )}
            </div>
            <div className="loan-row border" >
              <div className="loan-cell loan-label">Verðbætur</div>
              {loans.map((loan, i) =>
                <>
                  {getLoanTypeFromIndex(i) === "mixed" ?
                    <>
                      <div className={`loan-cell loan-${getLoanTypeFromIndex(i)}`}>
                        <div className="row">
                          <div className="col-6">{formatISK(loan[0].total_verdbaetur)} kr.</div>
                          <div className="col-6">-</div>
                        </div>
                      </div>
                    </>
                    :
                    <>
                      <div className={`loan-cell loan-${getLoanTypeFromIndex(i)}`}>
                        {loan.indexed ?
                          <span>{formatISK(loan.total_verdbaetur)} kr.</span>
                          :
                          <>-</>
                        }
                      </div>
                    </>
                  }
                </>

              )}
            </div>
            <div className="loan-row border">
              <div className="loan-cell loan-label">Verðbætur v/vaxta</div>
              {loans.map((loan, i) =>
                <>
                  {getLoanTypeFromIndex(i) === "mixed" ?
                    <>
                      <div className={`loan-cell loan-${getLoanTypeFromIndex(i)}`}>
                        <div className="row">
                          <div className="col-6">{formatISK(loan[0].total_verdbaetur_v_vaxta)} kr.</div>
                          <div className="col-6">-</div>
                        </div>
                      </div>
                    </>
                    :
                    <>
                      <div className={`loan-cell loan-${getLoanTypeFromIndex(i)}`}>
                        {loan.indexed ?
                          <span>{formatISK(loan.total_verdbaetur_v_vaxta)} kr.</span>
                          :
                          <>-</>
                        }
                      </div>
                    </>
                  }
                </>

              )}
            </div>
            <div className="loan-row border">
              <div className="loan-cell loan-label">Greiðslugjald</div>
              {loans.map((loan, i) =>
                <>
                  {getLoanTypeFromIndex(i) === "mixed" ?
                    <>
                      <div className={`loan-cell loan-${getLoanTypeFromIndex(i)}`}>
                        <div className="row">
                          <div className="col-6">{formatISK(loan[0].total_slip_fee)} kr.</div>
                          <div className="col-6">{formatISK(loan[1].total_slip_fee)} kr.</div>
                        </div>
                      </div>
                    </>
                    :
                    <div className={`loan-cell loan-${getLoanTypeFromIndex(i)}`}>{formatISK(loan.total_slip_fee)} kr.</div>
                  }
                </>
              )}
            </div>

            <div className="loan-row border">
              <div className="loan-cell loan-label">Heildargreiðsla</div>
              <div className="loan-cell loan-indexed"><strong>{formatISK(loanIndexed.total_paid)}</strong> kr.</div>
              <div className="loan-cell loan-mixed"><strong>{formatISK(this.props.multiLoan.loans[this.props.loanOptions.primaryLoans.length].total_paid + this.props.multiLoan.loans[this.props.loanOptions.primaryLoans.length + 1].total_paid)}</strong> kr.<br /></div>
              <div className="loan-cell loan-nonindexed"><strong>{formatISK(loanNonIndexed.total_paid)}</strong> kr.<br /></div>
            </div>
          </>
        }
      </>
    )
  }
}

export default asDrawer(LoanPaymentSummary);
