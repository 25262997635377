import React from 'react';
import ReactDOM from 'react-dom';
import {loan, maxLoan, buildParams, buildMultiParams, formatISK, formatPercentage, renderInflationOptions, renderPaymentFees, convertFromHumanReadablePercentage } from './utils';
import { setState, setMultiState, fetchLoans, fetchMultiLoans } from '../../redux/actions';
import Cleave from 'cleave.js/react';
import $ from 'jquery'

window.ReactDOM = ReactDOM

export default class LoanHeaderMore extends React.Component {

  constructor(props) {
    super(props);

    this.onSelectChange = this.onSelectChange.bind(this);
    this.customInflationForecastChange = this.customInflationForecastChange.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
    this.toggleInput = this.toggleInput.bind(this);

  }

  onSelectChange(key, event) {
    let _newState = [{key: key, value: event.target.value}]
    if (key == 'multiLoanLength') {
      _newState.push({key: 'primaryLoanLength', value: event.target.value})
    }
    if (key == 'multiLoanInflationForecast') {
      _newState.push({key: 'primaryInflationForecast', value: event.target.value})
    }
    Promise.resolve(this.props.dispatch(setMultiState(_newState)))
    .then(() => {
      this.props.dispatch(fetchMultiLoans(buildMultiParams(this.props.terms, this.props.loanOptions)));
      if (this.props.loan.loanDataReceived) {
        this.props.dispatch(fetchLoans(buildParams(this.props.terms)));
      }
    });
    
  }

  customInflationForecastChange(event) {
    var val = event.target.value.replace(/\./g, '');
    if (convertFromHumanReadablePercentage(val)>20) {
      val = 20
    }

    Promise.resolve(this.props.dispatch(setState({key: 'customInflationForecast', value: val})))
      .then(() => {
        this.props.dispatch(fetchMultiLoans(buildMultiParams(this.props.terms, this.props.loanOptions)));
      });
  }

  toggleInput() {
    let overrideInterestsShowInput = !this.props.terms.overrideInterestsShowInput
    Promise.resolve(this.props.dispatch(setState({ key: 'overrideInterestsShowInput', value: overrideInterestsShowInput })))
    .then(() => {
      if(overrideInterestsShowInput) {
        ReactDOM.findDOMNode(this.refs.multiInterestOverride).focus();
      }
    })
  }

  clearInterestOverride() {
    this.toggleInput()
    Promise.resolve(this.props.dispatch(setMultiState([
        { key: 'overrideInterests', value: false },
    ])))
    .then(() => {
      this.props.dispatch(fetchMultiLoans(buildMultiParams(this.props.terms, this.props.loanOptions)));
    });
  }

  onInputBlur(key, event) {
      var val = event.target.value.replace(/\./g, '');
      val = convertFromHumanReadablePercentage(val)
      if (val > 15) {
          val = 15
      }
      if (val === "") { val = 0}

      let overrideInterestsValues = val/100
      let overrideInterests = true

      Promise.resolve(this.props.dispatch(setMultiState([
          { key: key, value: overrideInterestsValues },
          { key: 'overrideInterests', value: overrideInterests },
      ])))
        .then(() => {
          this.props.dispatch(fetchMultiLoans(buildMultiParams(this.props.terms, this.props.loanOptions)));
        });
  }

  render() {
    if (this.props.multiLoan.loanDataReceived) {
      return (
        <>
          <div className="calculator row justify-content-center">
              <div className="col-8">
                <label htmlFor="multi-inflation">Verðbólguspá</label>
                <div className="select-wrapper">
                  <select onChange={this.onSelectChange.bind(this, 'multiLoanInflationForecast')} value={this.props.terms.multiLoanInflationForecast}>
                    {renderInflationOptions(this.props.loanOptions)}
                  </select>
                </div>
              </div>
              { this.props.terms.primaryInflationForecast === "custom" &&
              <div className=" settings-modal">
                <label htmlFor="custom-inflationForecast">&nbsp;</label>
                <div className="">
                <Cleave placeholder="Verðbólguspá"
                  id="custom-inflationForecast"
                  className="percentage-input"
                  options={{
                    numeral: true,
                    numeralDecimalMark: ',',
                    delimiter: '.'
                  }}
                  value={this.props.terms.customInflationForecast}
                  onChange={this.customInflationForecastChange}
                /><span></span>
                </div>
              </div>
              }
            </div>
            <div className="calculator row justify-content-center">
              <div className="col-8 interest-override">
                <label htmlFor="multi-interest-override">Vaxtaprósenta</label>
                <div className="select-wrapper-no-select">
                  { this.props.terms.overrideInterestsShowInput ?
                    [<Cleave 
                      key={1} 
                      ref="multiInterestOverride"
                      id="multi-interest-override"
                      className="percentage-input"
                      placeholder="Vaxtaprósenta"
                      options={{
                          numeral: true,
                          numeralDecimalMark: ',',
                          delimiter: '.'
                      }}
                      value={formatPercentage(100*this.props.terms.overrideInterestsValues)}
                      onBlur={this.onInputBlur.bind(this, 'overrideInterestsValues')} />,
                      <span />,
                      <a href="javascript:void(0)" className="multi-interest-override-close" onClick={() => this.clearInterestOverride()} title="Hreinsa mína vexti"><i className="fa fa-times" aria-hidden="true"></i></a>,
                      <a className="multi-interest-override-save"  href="javascript:void(0)" ><i className="fa fa-check" aria-hidden="true"></i></a>,
                    ]
                    :
                    <button className="multi-interest-override-open" onClick={() => this.toggleInput()}>Breyta vöxtum</button>
                  }
                </div>
              </div>
          </div>
          <div className="calculator row justify-content-center">
              <div className="col-8">
                <label htmlFor="multi-inflation">Árleg hækkun markaðsverðs</label>
                <div className="select-wrapper">
                  <select onChange={this.onSelectChange.bind(this, 'marketPriceIncrease')} value={this.props.terms.marketPriceIncrease}>
                    <option value="0.01">1%</option>
                    <option value="0.02">2%</option>
                    <option value="0.03">3%</option>
                    <option value="0.04">4%</option>
                    <option value="0.05">5%</option>
                    <option value="0.06">6%</option>
                    <option value="0.07">7%</option>
                    <option value="0.08">8%</option>
                    <option value="0.09">9%</option>
                    <option value="0.10">10%</option>
                  </select>
                </div>
              </div>
            </div>
        </>
      )
    } else {return <div />}
  }
}

