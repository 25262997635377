import React from 'react';


function asDrawer(WrappedComponent) {
  class Drawer extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        drawer: props.drawer
      }
      this.onDrawerToggle = this.onDrawerToggle.bind(this)
    }

    onDrawerToggle () {
      if(!this.props.disableDrawer) {
        this.setState({
          drawer: this.state.drawer == 'open' ? 'closed' : 'open'
        });
      }
    }

    render () {
      // Use JSX spread syntax to pass all props and state down automatically.
      return <WrappedComponent {...this.props} {...this.state} onDrawerToggle={this.onDrawerToggle} />;
    }
  }
  
  Drawer.defaultProps = {
    drawer: 'closed',
    disableDrawer: false,
  }
  return Drawer;
}



export default asDrawer;
